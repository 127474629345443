import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import axios from "axios";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import { backendApiUrl } from "../utils";
import * as builder from "../../_metronic/ducks/builder";
import LandingPage from "../../website/landingPage";

const Routes = withRouter(({ history }) => {
  const currentUrl = window.location.pathname;

  const dispatch = useDispatch();
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);

  const { isAuthorized, menuConfig, userLastLocation, user } = useSelector(
    ({ auth, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.user != null,
      userLastLocation: routerHelpers.getLastLocation(),
      user: auth.user,
    }),
    shallowEqual
  );

  const [load, setLoad] = useState(isAuthorized);
  const [isTokenValid, setIsTokenValid] = useState(isAuthorized);

  useEffect(() => {
    if (isAuthorized) {
      if (
        !currentUrl.includes("/forms/") &&
        !currentUrl.includes("/doc/view/") &&
        !currentUrl.includes("/contracts/view/") &&
        !currentUrl.includes("/email/unsubscribe")
      ) {
        axios
          .post(
            backendApiUrl("menus"),
            {},
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            setIsTokenValid(true);
            dispatch(builder.actions.setMenuConfig(res.data));
            setLoad(false);
          })
          .catch((err) => {
            setIsTokenValid(false);
            setLoad(false);
          });
      }
    } else {
      setIsTokenValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized, dispatch]);

  let allow = false;
  if (
    load &&
    !currentUrl.includes("/forms") &&
    !currentUrl.includes("/public") &&
    !currentUrl.includes("/doc/view/") &&
    !currentUrl.includes("/email/unsubscribe")
  ) {
    return "";
  } else {
    if (
      currentUrl.includes("/") ||
      currentUrl.includes("/auth") ||
      currentUrl.includes("/public") ||
      currentUrl.includes("/leads") ||
      currentUrl.includes("/email_builder") ||
      currentUrl.includes("/contracts") ||
      currentUrl.includes("/security") ||
      currentUrl.includes("/forms") ||
      currentUrl.includes("/builders/") ||
      currentUrl.includes("/companies/") ||
      currentUrl.includes("/opportunity/") ||
      currentUrl.includes("/loans") ||
      currentUrl.includes("/products") ||
      currentUrl.includes("/reports") ||
      currentUrl.includes("/workforces") ||
      currentUrl.includes("/prospects") ||
      currentUrl.includes("/contacts") ||
      currentUrl.includes("/error") ||
      currentUrl.includes("/error/error-v5") ||
      currentUrl.includes("/logout") ||
      currentUrl.includes("/integrations") ||
      currentUrl.includes("/imports") ||
      currentUrl.includes("/email")
    ) {
      allow = true;
    } else {
      menuConfig.header.items.forEach((module) => {
        if (currentUrl === "/" + module.page && module.permissions.global) {
          allow = true;
        } else if (
          currentUrl === "/" + module.page + "/create" &&
          module.permissions.add
        ) {
          allow = true;
        } else if (
          currentUrl.includes("/" + module.page + "/edit") &&
          module.permissions.update
        ) {
          allow = true;
        }
      });
    }
  }
  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        {!load && allow === false && <Redirect to="/error" />}

        {(currentUrl.includes("/forms/") ||
          currentUrl.includes("/public/") ||
          currentUrl.includes("/email/unsubscribe")) && (
          <Layout>
            <HomePage userLastLocation={userLastLocation} />
          </Layout>
        )}
        {!isTokenValid ? (
          window.location.hostname === "info.lendcrm.com" ? (
            <LandingPage />
          ) : (
            /* Render auth page when user at `/auth` and not authorized. */
            <AuthPage />
          )
        ) : userLastLocation.includes("/prospects") ||
          userLastLocation.includes("/loans") ||
          userLastLocation.includes("/leads") ? (
          <Redirect from="/auth" to={userLastLocation} />
        ) : user && (user.type === "Prospects" || user.type === "Contacts") ? (
          <Redirect from="/auth" to="/loans" />
        ) : (
          <Redirect from="/auth" to="/" />
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={LogoutPage} />

        {!isTokenValid ? (
          /* Redirect to `/auth` when user is not authorized */
          <Redirect to="/auth/login" />
        ) : currentUrl === "/builders/modules/form" ||
          currentUrl === "/builders/opportunity/form" ||
          currentUrl === "/builders/webforms/form" ||
          currentUrl === "/builders/tasks" ||
          currentUrl === "/builders/libraries/form" ? (
          <DndProvider backend={Backend}>
            <Layout>
              <HomePage userLastLocation={userLastLocation} />
            </Layout>
          </DndProvider>
        ) : (
          <Layout>
            <HomePage userLastLocation={userLastLocation} />
          </Layout>
        )}
      </Switch>
    </LayoutContextProvider>
  );
});

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(Routes);
